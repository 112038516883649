/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"spolupracujeme s "}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="css-iohcq5 --center pb--80 pt--80" name={"uvod"} parallax={false} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/17142/7a748459981040a0b51fd63c724ff733_ove=008cffx8__s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/17142/7a748459981040a0b51fd63c724ff733_ove=008cffx8__s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/17142/7a748459981040a0b51fd63c724ff733_ove=008cffx8__s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/17142/7a748459981040a0b51fd63c724ff733_ove=008cffx8__s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/17142/7a748459981040a0b51fd63c724ff733_ove=008cffx8__s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/17142/7a748459981040a0b51fd63c724ff733_ove=008cffx8__s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/17142/7a748459981040a0b51fd63c724ff733_ove=008cffx8__s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/17142/7a748459981040a0b51fd63c724ff733_ove=008cffx8__s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center fs--30" content={"<br>"}>
              </Title>

              <Text className="text-box" content={"<br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"ybdxlwk5xm9"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--30" content={"<span style=\"font-weight: bold; color: rgb(10, 10, 243);\"><a href=\"https://www.deti-detem.com/\" target=\"_blank\">iniciativa Děti dětem</a></span>"}>
              </Title>

              <Text className="text-box text-box--justify" style={{"maxWidth":1808}} content={"<span style=\"font-weight: bold; color: rgb(246, 7, 7);\">Iniciativa DĚTI DĚTEM zastřešuje sportovní, kulturní a společenské akce pro děti a mládež, pořádané na podporu charitativních projektů, přesněji dětí, jenž potřebují podat pomocnou ruku. DĚTI DĚTEM pomáhají s prezentací, medializací a osvětou spojenou s naplňování specifických potřeb a řešení specifických problémů hendikepovaných dětí. Hlavní činnost iniciativy DĚTI DĚTEM spočívá v organizaci sportovních, kulturních a společenských akcí pro děti a mládež, na kterých se formou vstupného, startovného a darů vybírají prostředky na podporu konkrétního dítěte.&nbsp;&nbsp;</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-8c77zl --style2 --full pb--60 pt--60" name={"75ba65wmh08"} fullscreen={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/45280582ad66477f9da7c6202f77b576_s=350x_.jpeg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/45280582ad66477f9da7c6202f77b576_s=660x_.jpeg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/45280582ad66477f9da7c6202f77b576_s=860x_.jpeg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/45280582ad66477f9da7c6202f77b576_s=1400x_.jpeg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/45280582ad66477f9da7c6202f77b576_s=2000x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/45280582ad66477f9da7c6202f77b576_s=660x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/45280582ad66477f9da7c6202f77b576_s=1400x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/45280582ad66477f9da7c6202f77b576_s=3000x_.jpeg);
    }
  
    `}>
        </Column>


        <Column className="pb--60 pt--60" name={"3ca2045phyg"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--30" content={"<span style=\"color: rgb(8, 8, 244); font-weight: bold;\"><a href=\"https://czechitband.com/\" target=\"_blank\">hudební kapela CZECH IT</a></span>"}>
              </Title>

              <Text className="text-box text-box--justify" style={{"maxWidth":1403}} content={"<span style=\"font-weight: bold; color: rgb(250, 2, 2);\">Mladá šestičlenná pop rocková kapela z Týnce nad Sázavou. Hraje výhradně autorskou tvorbu v anglickém jazyce. Ač by se mohlo zdát, že na české hudební scéně není pro anglicky zpívající kapelu místo, opak je pravdou a kapela se těší čím dál větší oblíbenosti.\n\nKapela odstartovala zejména rokem 2018, kdy byla jejich píseň Unexpected, nasazena do rotace rádia Evropa 2, která se držela vždy na předních příčkách hitparád E2 Music Chart. Následovaly singly Hope You Know a Letters, které se zapsaly do povědomí posluchačů právě z rádia Evropa 2. \n\nNásledovalo umístění na 3. místě jako Objev roku na prestižních hudebních cenách Žebřík 2019 a získání Divoké karty Evropy 2. Přidalo se nespočet vystoupení a účinkování na festivalech po celé České republice jako například Sázavafest, Brod1995, Okoř se šťávou, Festival Přeštěnice, turné s Pekařem aj.</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-ykbgnc --full pb--60 pt--60" name={"wyff11fy6i"} fullscreen={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/789328613f1c43edafd5940bf5cc0b38_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/789328613f1c43edafd5940bf5cc0b38_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/789328613f1c43edafd5940bf5cc0b38_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/789328613f1c43edafd5940bf5cc0b38_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/789328613f1c43edafd5940bf5cc0b38_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/789328613f1c43edafd5940bf5cc0b38_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/789328613f1c43edafd5940bf5cc0b38_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/789328613f1c43edafd5940bf5cc0b38_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"1z6kskvvm51j"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--30" content={"<span style=\"font-weight: bold; color: rgb(37, 7, 241);\"><a href=\"https://www.krokdozivota.cz/\" target=\"_blank\">Nadační fond Krok do života</a></span>"}>
              </Title>

              <Text className="text-box text-box--justify" style={{"maxWidth":1449}} content={"<span style=\"font-weight: bold; color: rgb(246, 4, 4);\">Dáváme dětem z dětských domovů možnost zvolit si lepší cestu, po které se mohou vydat. Víme, že jejich cesta bude možná složitější a náročnější než pro jiné, ale také víme, že to mohou dokázat. Dodáváme dětem motivaci, odhodlanost a chuť vybudovat si plnohodnotný život. Snažíme se, aby děti zjistily co je baví a objevily svůj potenciál. Ve spolupráci s řediteli a vychovateli, pracujících v dětských domovech, pro děti vytváříme další možnosti jejich rozvoje a pomáháme jim na jejich cestě.</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1d9lmx9 --full pb--60 pt--60" name={"ld9op2d0tx"} fullscreen={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/9c4ad4ae9def44c0a9118799eebe63f4_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/9c4ad4ae9def44c0a9118799eebe63f4_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/9c4ad4ae9def44c0a9118799eebe63f4_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/9c4ad4ae9def44c0a9118799eebe63f4_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/9c4ad4ae9def44c0a9118799eebe63f4_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/9c4ad4ae9def44c0a9118799eebe63f4_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/9c4ad4ae9def44c0a9118799eebe63f4_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/9c4ad4ae9def44c0a9118799eebe63f4_s=3000x_.jpg);
    }
  
    `}>
        </Column>


        <Column className="pb--60 pt--60" name={"1z6kskvvm51j"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--30" content={"<span style=\"color: rgb(7, 7, 244);\"><a href=\"https://www.facebook.com/rotaractclubhk/\" target=\"_blank\"><span style=\"font-weight: bold;\">Rotaract Club Hradec Králové</span>&nbsp;</a></span><br>"}>
              </Title>

              <Text className="text-box text-box--justify" style={{"maxWidth":1449}} content={"<span style=\"color: rgb(246, 6, 6); font-weight: bold;\">Rotary International je nejstarší mezinárodní klubovou organizací na světě. Vznikla v roce 1905 v americkém Chicagu, v současné době působí ve více než 200 zemích, kde v 35 000 rotary klubech sdružuje 1,2 mil. členů – žen i mužů, vůdčích osobností a profesionálů z různých oborů.\n\nTuto celosvětovou síť spojuje myšlenka vyjádřená mottem „Service Above Self“ (Služba na prvním místě), kdy rotariáni dobrovolně věnují svůj čas a schopnosti ve prospěch druhých, a to jak v rámci vlastní obce, tak v mezinárodním měřítku. Na základě etických principů a hodnot, jako je přátelství, integrita, diverzita, služba a vůdcovství, tak naplňují své ušlechtilé poslání – dělat svět lepším.\n\nČlenové rotary klubů mění životy lidí v místech, kde žijí. Být členem rotary klubu je současně příležitostí pro aktivní účast na něčem, co může změnit svět, a co pak přináší zadostiučení a celoživotní přátelství.</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}